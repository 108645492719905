import axios from 'axios';

let prefix = "/exchanger-pair"

const ExchangerPairApi = {
	
	listExchangerPair(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	listCryptoCoin(payload){
		return axios.get( prefix + "/crypto-coin-list", {params: payload})
	},
}

export default ExchangerPairApi;