import Vue from 'vue'

import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark : false,
		themes: {
			light: {
				primary: '#60d2bd',
				secondary: '#202020',
				'tertiary' : '#3EA6FF',
				'background': '#181818',
				'opposite': '#FFFFFF',
			},
			dark: {
				primary: '#60d2bd',
				secondary: '#202020',
				'tertiary' : '#3EA6FF',
				'background': '#181818',
				'opposite': '#FFFFFF',
			},
		},
	}
})