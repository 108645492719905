import axios from 'axios';

let prefix = "/arbitrage-server-transaction"

const ArbitrageServerTransactionApi = {
	
	listAllArbitrageServerTransaction(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
}

export default ArbitrageServerTransactionApi;