import axios from 'axios';

let prefix = "/arbitrage-server"

const ArbitrageServerApi = {
	
	listAllArbitrageServer(payload){
		return axios.get( prefix + "/list-all", {params: payload})
	},
}

export default ArbitrageServerApi;